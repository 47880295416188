.AdminGigs {
  .button-container {
    margin: 30px 0;
  }
  form {
    .form-group {
      margin-bottom: 10px;
    }
  }
  .form-control-typeahead {
    height: 40px;
  }
  .icon-cell {
    svg {
      margin: 5px 10%;
    }
  }
}
