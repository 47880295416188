.Video {
  .video-links {
    padding-top: 10px;
    .video-link {
      margin-top: 10px;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 0px;
    height: 0;
    margin: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    @media (min-width: 768px) {
      margin-bottom: 30px;
    }
  }
}
