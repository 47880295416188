.TopMenu {
    border-radius: 0;
    z-index: 999;
    padding:0;
    &.navbar { 
        margin-bottom: 0;
        background-color: #FFFAFA;
        background-image: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        &.fixed-top {
            border: none;
        }
        .navbar-brand {
            font-family: 'Lato', sans-serif;
            font-weight: bold;
            font-size: 16px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.2px;
            a {
                color: #5d6371;
                text-decoration: none;
            }
        }
        .navbar-collapse {
            .navbar-nav {
                .nav-link {
                    font-size: 14px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    letter-spacing: 0.2px;
                    text-align: right;
                    color: #5d6371;
                    padding: 10px 15px;
                    line-height: 20px;
                    text-shadow: 0 1px 0 rgba(255, 255, 255, .25);
                    &:hover
                    {
                        cursor: pointer;
                        text-decoration: unset;
                        color: #8eccd5;
                        background-color: transparent;
                    }
                }
            }
        }
        .navbar-toggler:focus {
            text-decoration: none;
            outline: 0;
            box-shadow: none;
        }
        &.navbar-expand-lg {
            .navbar-collapse {
                .navbar-nav {
                    .nav-link {
                        text-align: center;
                    }
                }
            }
        }
    }
}




.nav > li > div,
.navbar-brand, .navbar-nav > li > a {
    text-shadow: 0 1px 0 rgba(255, 255, 255, .25);
}

/* .navbar-nav .nav-link {
    position: relative;
    display: block !important;
    padding: 10px 15px;
}

@media (min-width: 768px)
{
    .navbar-nav .nav-link {
        padding-top: 15px;
        padding-bottom: 15px;
        line-height: 20px;
    }
} */

.TopMenu .icons-container {
    display: inline-block;
    padding: 12px 0;
  }
  
.TopMenu .social-link {
    display: inline-block;
    margin: 0 3px;
    padding: 2px;
    border: 0px;
    color: #5d6371;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    font-size: 16px;
    text-align: center;
}

.TopMenu .social-link svg {
    display: inline-block;
    margin-bottom: 2px;
}

@media (max-width: 390px){
    .TopMenu .icons-container {
        display: none;
      }
      
}