.Music {
  .item-container {
    text-align: center;
    margin-bottom: 30px;
    img:hover {
      opacity: 0.7;
    }
  }

  .music-title {
    border: solid 2px #f4e4e1;
    font-family: Lato;
    font-size: 16px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #5d6371;
    padding: 10px 20px;
    margin: 20px auto;
    text-align: center;
    display: inline-block;
    &:hover {
      cursor: default;
    }
  }
}
