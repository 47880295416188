.AdminBlogPosts {
  .button-container {
    margin: 30px 0;
  }
  .editor {
    border: 1px solid #f1f1f1;
    padding: 10px;
    min-height: 300px;
  }
  .edit-blog {
    margin: 30px 0;
  }
  .form-group {
    margin-bottom: 10px;
  }
}
