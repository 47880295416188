.Login {
  .form {
    margin-top: 30px;
    .form-control:focus {
      border-color: #f4e4e1;
      outline: 0;
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba(230, 197, 205, 0.6);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba(230, 197, 205, 0.6);
    }

    .has-error .help-block,
    .has-error .control-label,
    .has-error .radio,
    .has-error .checkbox,
    .has-error .radio-inline,
    .has-error .checkbox-inline,
    .has-error.radio label,
    .has-error.checkbox label,
    .has-error.radio-inline label,
    .has-error.checkbox-inline label {
      color: #df9898;
    }

    .has-error .form-control {
      border-color: #df9898;
    }
  }
  .button-container {
    margin-top: 30px;
    .btn {
      color: #fff;
      background-color: #8eccd5;
      border: none;
      border-radius: 0px;
      padding: 20px 70px;
      background-image: none !important;
    }
  }
}
