.slider-wrapper {
  position: relative;
  height: 70vh;
  overflow: hidden;
  .previousButton,
  .nextButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
    width: 32px;
    height: 32px;
    cursor: pointer;
    color: white;
  }
  .previousButton {
    left: 10px;
    /* -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
            transform: rotate(180deg) translateY(calc(50% + 0px)); */
  }
  .nextButton {
    right: 0px;
  }
  .slide {
    background-size: cover !important;
    background-position-x: right !important;
    background-position-y: middle !important;
    &.slider-content {
      text-align: center;
      .inner {
        padding: 0 70px;
        box-sizing: border-box;
        position: absolute;
        width: 55%;
        top: 40%;
        left: 25%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        button {
          -webkit-transition-delay: 0s;
          transition-delay: 0s;
          background-color: transparent;
          border: none;
          font-family: Lato;
          font-size: 24px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.2px;
          color: #fff5f6;
          margin-top: 15px;
        }
      }
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        rgba(234, 240, 241, 0.5),
        rgba(221, 132, 162, 0.6)
      );
      bottom: 0;
      left: 0;
    }
    @media (max-width: 992px) {
      &.slide-1 {
        background-position-x: 60% !important;
      }
      &.slide-3 {
        background-position-x: 40% !important;
      }
      &.slide-2 {
        background-position-x: 90% !important;
      }
      &.slide-4 {
        background-position-x: 70% !important;
      }
    }
    @media (max-width: 768px) {
      &.slide-1 {
        background-position-x: 60% !important;
      }
      &.slide-3 {
        background-position-x: 40% !important;
      }
      &.slide-2 {
        background-position-x: 90% !important;
      }
      &.slide-4 {
        background-position-x: 70% !important;
      }
    }
    @media (max-width: 576px) {
      &.slide-1 {
        background-position-x: 60% !important;
      }
      &.slide-3 {
        background-position-x: 40% !important;
      }
      &.slide-2 {
        background-position-x: 90% !important;
      }
      &.slide-4 {
        background-position-x: 70% !important;
      }
      .slider-wrapper {
        height: 50vh;
      }
    }
    @media (max-width: 480px) {
      &.slide-1 {
        background-position-x: 60% !important;
      }
      &.slide-3 {
        background-position-x: 40% !important;
      }
      &.slide-2 {
        background-position-x: 90% !important;
      }
      &.slide-4 {
        background-position-x: 70% !important;
      }
    }
  }
}

.slider-content .inner button .glyphicon {
  margin-left: 10px;
  top: 2px;
}

.slider-content .inner h1 {
  margin: 0 auto;
  line-height: 1;
  font-family: Lato;
  font-size: 40px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #ffffff;
  margin-bottom: 15px;
  margin-top: 15px;
  text-align: center;
}

.slider-content .inner img {
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 998px) {
  .slider-content .inner img {
    width: 75%;
  }
}

.slider-content .inner p {
  color: #ffffff;
  font-size: 14px;
  line-height: 1.5;
  margin: 20px auto 20px;
  max-width: 640px;
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

@media (max-width: 667px) {
  .slider-content .inner {
    width: 100%;
    left: 50%;
    padding: 0 50px;
  }
  .slider-content .inner img {
    margin-top: 20px;
    width: 60%;
  }
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

@media (max-width: 480px) {
  .slider-content .inner img {
    margin-top: 60px;
    width: 100%;
  }
}

.slide h1,
.slide img {
  transition: all 0.3s ease;
  -webkit-transform: translateY(-40px);
  transform: translateY(-40px);
  opacity: 0;
}

.slide button {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.slide p {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous img,
.slide.current img,
.slide.animateIn.next img,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
  opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.slide.animateIn.next h1 {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.slide.animateIn.previous img {
  -webkit-transition-delay: 1.2s;
  transition-delay: 1.2s;
}

.slide.animateOut h1 {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.slide.animateOut img {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}

.slide.animateOut p {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
