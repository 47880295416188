@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');

body {
  font-family: 'Lato', Helvetica, sans-serif;
}

.confirmation-message .button-container {
  margin: 30px 0;
}

.section-heading {
  font-size: 20px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #5d6371;
  text-align: center;
}

.section-heading-line {
  width: 50px;
  height: 6px;
  opacity: 0.5;
  background-color: #eacbc4;
  text-align: center;
  margin: 0 auto 40px auto;
}

.section-heading-line.white {
  background-color: #fff;
}

.modal-footer .btn {
  color: #8eccd5;
  background-color: transparent;
  border: 2px solid #8eccd5;
  border-radius: 0px;
  padding: 10px 50px;
  background-image: none !important;
}

.btn {
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

.btn:hover {
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  transform: scale(0.95);
}

.modal-body img {
  margin-bottom: 10px;
}

.modal-header {
  border-bottom: 1px solid #eacbc4;
}

.modal-footer {
  border-top: 1px solid #eacbc4;
  text-align: center;
}
